.center-point {
	position: absolute;
	width: 4px;
	height: 4px;
	background-color: red;
	border-radius: 2px;
	z-index: 1;
}
.controlButton {
	width: 30px;
	height: 30px;
	cursor: pointer;
	filter: drop-shadow(0px 0px 4px black);
}
.outputButton {
	position: absolute;
	padding: 5px;
	margin: 5px;
	font-size: 10px;
	font-weight: bold;
	color: black;
	background-color: white;
	cursor: pointer;
	bottom: 0;
	right: 0;
}
.control-overlay {
	position: absolute;
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	.control-overlay-col {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
}
