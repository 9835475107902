.autoSizeTextareaWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: inherit;
	max-height: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	text-align: inherit;
	max-height: inherit;
}

.autoSizeTextarea {
	width: inherit;
	max-height: inherit;
	background-color: transparent;
	border: none;
	outline: none;
	resize: none;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	text-align: inherit;
	cursor: auto;
	&:disabled {
		opacity: 0.2;
	}
}

/*
 *  Scroll bar styling
 */

.autoSizeTextarea::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: transparent;
	border-radius: 10px;
}

.autoSizeTextarea::-webkit-scrollbar {
	width: 10px;
	background-color: transparent;
}

.autoSizeTextarea::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #333;
	cursor: pointer;
}
