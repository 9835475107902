.sessionIdContainer {
	color: #ffffff;
	text-shadow: 0px 2px 10px #00000029;
	display: flex;
	font-family: Roboto;
	opacity: 0.5;
	cursor: pointer;
	pointer-events: all;
}
.sessionIdContainer:hover {
	opacity: 1;
}
