.peerConnectionStatusIcon {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	opacity: 0.5;
	background-color: #1e1f22;
	z-index: 2;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	.warningIconWrapper {
		width: 25px;
		height: 25px;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

.peerConnectionStatusIcon:hover {
	display: flex !important;
	opacity: 0.95;
}

.peerConnectionStatusSnackbar {
	display: flex;
	flex-direction: column;
}
