@keyframes collapseFeedbackTimeoutWrapper {
	0% {
		background-color: #23cd1d;
		width: 100%;
	}
	100% {
		background-color: #f04747;
		width: 0;
	}
}

@keyframes collapseFeedbackCardWrapper {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.feedbackCardWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: min-content;
	background-color: #1e1f22e6;
	border-radius: 5px;
	overflow: hidden;
	gap: 10px;
	&:hover {
		.feedbackCardTimeoutWrapper {
			display: none;
		}
	}
	.feedbackCardTimeoutWrapper {
		display: flex;
		background-color: white;
		width: 100%;
		padding-block: 1%;
	}
}
