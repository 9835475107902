.chip {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #666666;
	padding: 2px 12px;
	border-radius: 12px;
	margin-left: 4px;
	.chip-label {
		font-size: 11px;
		font-weight: bold;
		line-height: 17px;
		margin: 0;
	}
}
