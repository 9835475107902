.Session {
	height: 100%;
	outline: none;

	.sessionInfoContainer {
		* {
			z-index: 1;
		}
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		justify-content: space-between;

		.sessionInfoContainerUpper {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			pointer-events: none;
		}

		.sessionInfoContainerLower {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
			margin: 20px;
			width: fit-content;
			max-height: 90vh;
			z-index: 32;
		}

		.sessionIdContainer {
			margin: 15px;
		}

		.optionContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 20px;
			gap: 10px;
			pointer-events: all;

			.optionButton {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 26px;
				color: rgba(0, 0, 0, 0.15);
				background-color: #ffffff;
				opacity: 0.5;
				img {
					color: black;
					opacity: 0.5;
					font-size: 25px;
				}
			}
			.optionButton:hover {
				opacity: 0.8;
				img {
					opacity: 0.8;
				}
			}
		}
	}
	.localVideoDragSection {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
	.urlShareDragSection {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
}

// disable selection inside the session to make the usage of joystick easier
img,
div,
span,
p {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}
