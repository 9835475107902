.endSessionWrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	height: 100vh;
	z-index: 6;
	background-color: #242525;
	.container {
		display: flex;
		flex-direction: column;
		background-color: #444444;
		padding: 70px;
		.session {
			font-family: 'Industry';
			font-size: 40px;
			font-weight: 500;
			margin-top: 10px;
		}
		.minutes {
			font-size: 17px;
			margin-top: 16px;
		}
		.endMsg {
			color: #aaaaaa;
			font-size: 17px;
			margin-top: 48px;
		}
		.feedbackWrapper {
			margin-top: 10px;
		}
	}
}
