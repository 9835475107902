.wrapper {
	position: relative;
	width: -moz-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: fit-content;
	overflow: hidden;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	margin: 0;
	padding: 0;
}
.content {
	display: flex;
	flex-wrap: wrap;
	width: -moz-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: 0;
	padding: 0;
	transform-origin: 0% 0%;
}
.content img {
	pointer-events: none;
}
