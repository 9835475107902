.dragContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 50;
}

.dragHandle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 5px;
	border-radius: 5px;
	background-color: #1e1f22;
	border: 0.5px solid gray;
	margin-bottom: 2px;
	pointer-events: all;
	&:hover {
		@extend .dragHandleHover;
	}
	transition: all 0.2s ease-out;
	.dragHandleIcon {
		width: 15px;
		height: 15px;
		display: none;
		pointer-events: none;
	}
}

.dragHandleHover {
	height: 10px;
	width: 10px;
	cursor: pointer;
	padding: 5px;
	.dragHandleIcon {
		display: flex;
	}
}
