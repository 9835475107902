.selectContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
	width: 350px;
	gap: 10px;
	.select-box {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 350px;
		.options-container {
			background: #353538;
			color: white;
			max-height: 0px;
			opacity: 0;
			border-radius: 4px;
			overflow: hidden;
			order: 1;

			&.active {
				max-height: 240px;
				opacity: 1;
				overflow-y: auto;
			}

			&.active + .wrapper-select .selected img {
				transform: rotateX(180deg);
			}

			&::-webkit-scrollbar {
				width: 5px;
				background: #353538;
				border-radius: 8px;
			}

			&::-webkit-scrollbar-thumb {
				background: #3c7a36;
				border-radius: 8px;
			}

			.option-container {
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				.option {
					display: flex;
					align-items: center;
					border-radius: 4px;
					input {
						display: none;
						&:disabled {
							background-color: gray;
						}
					}
					label {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 10px 10px;
						gap: 20px;
						cursor: inherit;
						.option-label-disabled {
							color: #c0c0c0;
						}
						.check {
							display: flex;
							align-items: center;
						}
					}
				}
				&:hover {
					background: #3c7a36;
				}
			}
			.option-container-disabled {
				@extend .option-container;
				&:hover {
					background: unset;
					cursor: unset;
				}
			}
		}
		.wrapper-select {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			background-color: #353538;

			.selected {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				color: white;
				position: relative;
				order: 0;
				padding: 10px 10px;
				gap: 20px;
				cursor: pointer;
				img {
					transition: 0.2s ease-in-out;
				}
			}
		}
	}
}
