@keyframes shake {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(1px);
	}
	40% {
		transform: translateX(-1px);
	}
	60% {
		transform: translateX(1px);
	}
	80% {
		transform: translateX(-1px);
	}
	100% {
		transform: translateX(0);
	}
}

.feedbackFormWrapper {
	display: flex;
	// Reversed in order for the css-selectors to work with events like :hover and :disable to modify sibling elements
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	padding: 20px;
	gap: 20px;
	color: white;
	text-align: center;
	font-family: Industry !important;
	font-size: 13px;

	.feedbackFormTitle {
		font-size: 15px;
	}

	.feedbackFormRatingWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 5px;
		> span {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			justify-content: center;
			gap: 5px;
		}
		.feedbackFormRatingTooltip {
			margin: 0 !important;
		}
		.feedbackFormRatingScale {
			display: flex;
			justify-content: space-between;
			width: 100%;
			font-size: smaller;
		}
	}

	.feedbackFormRatingWrapperRequired {
		animation: shake 0.5s;
	}

	.feedbackFormMessageWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: left;
	}

	.feedbackFormButtonWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 20px;
		> div {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			gap: 40%;
		}
	}

	.feedbackFormButton {
		cursor: pointer;
		background-color: transparent;
		border: none;
		outline: none;
		font-family: inherit;
		font-size: 15px;
		color: inherit;
		opacity: 0.8;
		&:hover {
			opacity: 1;
		}
		&:disabled {
			cursor: auto;
			opacity: 0.1;
		}
	}

	.feedbackFormSubmitButton {
		@extend .feedbackFormButton;
	}

	.feedbackFormDismissButton {
		@extend .feedbackFormButton;
	}

	.feedbackFormTurnOffButton {
		@extend .feedbackFormButton;
		font-size: x-small;
		opacity: 0.5;
		&:hover {
			opacity: 0.8;
		}
	}

	&:hover {
		.feedbackFormSubmitButton:disabled {
			~ .feedbackFormRatingWrapper {
				@extend .feedbackFormRatingWrapperRequired;
			}
		}
	}
}

.feedbackFormWrapperHidden {
	visibility: hidden;
}
