@keyframes initialAuraTransition {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.navViewContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	bottom: 8px;
	left: 8px;
	right: 8px;
	gap: 16px;
	background-color: #444444;
	border-radius: 8px;
	min-height: 220px;
}

.navViewContainer .navViewContainerBackground {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #444444;
	border-radius: inherit;
}

.navViewContainer .navViewContainerBackgroundAura {
	-webkit-mask-image: radial-gradient(ellipse 100% 100% at 50% 0%, black 30%, transparent 100%);
	mask-image: radial-gradient(ellipse 100% 100% at 50% 0%, black 30%, transparent 100%);
	animation: initialAuraTransition 5s linear forwards;
}

.navViewContainerDriving {
	@extend .navViewContainer;
	background-color: transparent;

	.navigation-video {
		border-radius: 8px;
		background-color: inherit;
	}
}

.navViewContainerHidden {
	visibility: hidden;
}
