.msgContainer {
	display: flex;
	width: fit-content;
	background-color: #58b255;
	z-index: 2;
	padding: 9px 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	top: -5px;
	color: #ffffff;
	font-size: 15px;
	align-items: center;
	gap: 8px;
	.msgContainerTimer {
		background-color: rgba(255, 255, 255, 0.2);
		border-radius: 15px;
	}
}
