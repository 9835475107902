.navigation-video {
	display: flex;
	align-items: center;
	justify-content: center;
	contain: content;
}

.navigation-video .loading-indicator-container {
	position: absolute;
	align-self: center;
	justify-self: center;
	left: 50%;
	transform: translate(-50%, 0);
}

.navigation-video .loading-indicator {
	border: 3px solid transparent;
	border-top: 3px solid white;
	border-bottom: 3px solid white;
	border-right: 3px solid white;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 2s linear infinite;
}

.navigation-video video {
	position: relative;
	object-fit: contain;
	border-radius: inherit;
	background-color: rgba(30, 31, 34, 0.75);
}

.navigation-video .joystick-container {
	position: absolute;
	display: flex;
	flex-direction: column;
}
.joystick-offset {
	height: 25%; 
	width: 100%;
}

.greyVideo {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

.navigation-video .overlay-buttons {
	position: absolute;
	width: inherit;
	height: inherit;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0.95 !important;
	z-index: 5;
}
.navigation-video .overlay-buttons-background {
	background-color: rgba(30, 31, 34, 0.75);
}
.autoParkButtonContainer {
	padding: 14px 32px;
	border-radius: 90px;
	background: #58b255;
	border-radius: 30px;
	border: solid 1px #58b255;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
}
.autoParkButtonContainer:hover {
	background: #337831;
	border: solid 1px #337831;
}

.circleTextContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 90px;
	font-size: 15px;
	.iconWrapper {
		width: 30px;
		height: 30px;
	}

	.greyCircle {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		cursor: pointer;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 42px;
		background-color: #dddddd;
	}
	.greyCircle:hover {
		background-color: #aaaaaa;
	}
	.greyCircle:hover .iconWrapper {
		fill: #ffffff;
	}

	.autoParkClose {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		cursor: pointer;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 42px;
		background-color: #d92e2e;
		opacity: 0.5;
		fill: #ffffff;
	}
	.autoParkClose:hover {
		opacity: 1;
	}
}

.hidden {
	visibility: hidden;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

// This is a css class within A-frame library.
// Our users should not be seeing this functionality
.a-enter-vr-button {
	visibility: hidden;
}
