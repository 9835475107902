.robotFeaturesContainer {
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	align-items: flex-end;
	margin-bottom: 8px;
	gap: 8px;
	z-index: 31;
	.blackCircle {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #1e1f22;
		opacity: 0.5;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		opacity: 0.9;
		filter: brightness(0.8);
		&:hover {
			filter: brightness(1);
		}
		&.disabled {
			opacity: 0.5;
			transition: 0.5s;
		}
	}
	.selectedBlackCircle {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #242525;
		opacity: 0.5;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		opacity: 0.9;
		&.disabled {
			opacity: 0.5;
			transition: 0.5s;
		}
	}
	.greenCircle {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #58b255;
		opacity: 0.5;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		opacity: 0.9;
	}
	.iconWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 26px;
		height: 26px;
	}
	.disabled {
		background-color: #aaaaaa;
		cursor: default;
		pointer-events: none;
	}
	.badge {
		position: absolute;
		top: 0;
		width: 20px;
		height: 20px;
		transform: translate(60%, 0);
	}
}
