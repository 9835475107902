.statusBarMsgcontainer {
	box-sizing: border-box;
	background: #242525;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
		0px 0px 1px rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	color: white;
	gap: 30px;
	max-width: 30vw;
	min-width: 20%;
	&.isMinimized {
		justify-content: center;
		border-radius: 80px;
		width: 20px;
		opacity: 0.5;
		padding: 20px;
		height: 25px;
	}
	&.isMinimized:hover {
		opacity: 0.95;
		cursor: pointer;
	}
	.statusicontitle {
		display: flex;
		flex-direction: column;
		gap: 10px;
		.status {
			display: flex;
			align-items: center;
			gap: 10px;
			.statusIcon {
				width: 24px;
				height: 24px;
				padding-right: 0;
			}
			.statusTitle {
				font-family: Industry;
				font-weight: 400;
				position: relative;
				font-size: 24px;
				line-height: 24px;
				font-style: normal;
			}
		}
		.statusAction {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			font-family: 'Roboto';
			font-style: normal;
		}
	}

	.closeIconWrapper {
		margin-left: 18px;
		padding-right: 4px;
		cursor: pointer;
	}
}

.navIcon {
	height: 24px;
	width: 24px;
}
