.urlShareContainer {
	width: 230px;
	background-color: #1e1f22;
	padding: 5px;
	border-radius: 10px;
	z-index: 50;
	display: block;
	position: absolute;

	.urlShareTitle {
		text-align: center;
		color: white;
		height: 20px;
		font-family: 'Roboto', sans-serif;

		.urlShareMinimize {
			position: absolute;
			top: 2px;
			left: 4px;
			width: 25px;
			height: 25px;
			justify-content: center;
			align-items: center;
			opacity: 0.85;

			&:hover {
				opacity: 1;
			}
		}
	}

	.urlShareInput {
		display: block;
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.roundedButton {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 23px;
		border-radius: 25px;
		background-color: #1e1f22;
		opacity: 0.85;
		z-index: 4;
		margin-right: 5px;
		overflow: hidden;
		cursor: pointer;
		&.red {
			background-color: #d92e2e;
		}
		&.green {
			background: #56ae4d;
		}
		&.disabled {
			background-color: #aaaaaa;
			cursor: default;
		}
		&:not(.disabled):hover {
			opacity: 1;
		}
	}

	.roundedButtonIcon {
		opacity: 0.85;
		.roundedButton:not(.disabled) &:hover {
			opacity: 1;
		}
	}

	.HorizontalLine {
		width: 100%;
		height: 1px;
		background-color: #aaaaaa;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	input {
		background-color: #1e1f22;
		border: none;
		outline: none;
		color: white;
		text-align: center;
		font-family: Industry;
		font-size: 13px;
		width: 100%;
	}
}
