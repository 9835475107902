.remoteVideo {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: 8px;
	border-radius: 8px;
}

.remoteVideoFullScreen {
	@extend .remoteVideo;
	margin: 0;
	bottom: 0;
	border-radius: 0;
	.dynamic-media {
		max-height: unset;
		max-width: unset;
	}
}

.sessionStartContainer {
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.9;
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	font-size: 15px;
	gap: 18px;
}

.sessionStartLoading {
	border: 8px solid transparent;
	border-top: 8px solid white;
	border-bottom: 8px solid white;
	border-right: 8px solid white;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
}

.greyVideo {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
