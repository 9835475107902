.dockingContainer {
	position: absolute;
	top: calc(50% - 96px);
	left: calc(50% - 112px);
	width: 224px;
	height: 195px;
	background: #1e1f22 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 10px #00000029;
	border-radius: 10px;
	opacity: 0.9;
	z-index: 3;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding: 10px 20px 10px 20px;
	.dockingTitle {
		font: normal normal normal 18px/21px Industry;
		letter-spacing: 0px;
		color: #56ae4d;
		opacity: 1;
	}
	.dockingStatus {
		text-align: center;
		font: normal normal normal 15px/20px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
	}
	.dockingIconWrapper {
		width: 45px;
		height: 55px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.dockingNote {
		text-align: center;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
		span {
			color: #56ae4d;
		}
	}
}

.pButton {
	color: #58b255;
}

.textBold {
	font-weight: bold;
}

.textIcon {
	position: relative;
	margin-bottom: -5px;
	margin-inline: 2px;
	height: 20px;
}
