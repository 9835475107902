@keyframes initialAuraTransition {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.dynamic-media {
	width: 100%;
	position: relative !important;
	background-color: #444444;
	border-radius: inherit;
}

.dynamic-media .dynamic-media-background {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #444444;
	border-radius: inherit;
}

.dynamic-media .dynamic-media-backgroundAura {
	-webkit-mask-image: radial-gradient(ellipse 90% 300% at 50% 50%, black 40%, transparent 80%);
	mask-image: radial-gradient(ellipse 90% 300% at 50% 50%, black 40%, transparent 80%);
	animation: initialAuraTransition 5s linear forwards;
}

.dynamic-media .resize-container-inner {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.dynamic-media .media-cropping-box {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.dynamic-media video {
	pointer-events: none;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	background-color: black;
}

.dynamic-media-full-screen {
	padding-inline: 8px;
}

.mini-view-container-hidden {
	@extend .mini-view-container;
	opacity: 0;
	pointer-events: none;
}

.mini-view-container {
	width: inherit;
	height: inherit;
	position: absolute;
	z-index: 5;
	pointer-events: none;
	transition: opacity 0.5s ease-out;
}

.mini-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	border-radius: 8px;
	pointer-events: all;
}

.mini-view-controls {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 5px;
	.mini-view-control-button-wrapper {
		width: 30px;
		height: 30px;
		border-radius: 20px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		opacity: 0.8;
		color: #ffffff;
		filter: brightness(0.8);
		&:hover {
			filter: brightness(1);
		}
		&.disabled {
			opacity: 0.5;
			transition: 0.5s;
		}
	}
	.mini-view-control-zoom-dropdown-button {
		@extend .mini-view-control-button-wrapper;
		z-index: 1;
	}
	.mini-view-control-zoom-dropdown-button-disabled {
		@extend .mini-view-control-zoom-dropdown-button;
		pointer-events: none;
	}
	.mini-view-control-zoom-dropdown-button:hover ~ {
		.mini-view-control-zoom-dropdown-content {
			visibility: visible;
		}
	}
	.mini-view-control-zoom-dropdown-button:hover .mini-view-control-zoom-dropdown {
		border-radius: 20px;
		color: #ffffff;
		background-color: #000000;
	}
	.mini-view-control-zoom-dropdown {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.mini-view-control-zoom-dropdown-content {
		position: absolute;
		display: flex;
		visibility: hidden;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px 5px;
		padding-top: 40px;
		gap: 15px;
		border-radius: 20px;
		color: #ffffff;
		background-color: #000000;
		z-index: 0;
		&:hover {
			visibility: visible;
		}
	}
	.mini-view-control-zoom-dropdown-content-disabled {
		@extend .mini-view-control-zoom-dropdown-content;
		pointer-events: none;
	}
	.zoom-dropdown-content-option {
		padding: 5px;
		cursor: pointer;
		border-radius: 20px;
		&:hover {
			color: #000000;
			background-color: #ffffff;
			opacity: 0.7;
		}
	}
	.zoom-dropdown-content-option-selected {
		@extend .zoom-dropdown-content-option;
		cursor: default;
		color: #000000;
		background-color: #ffffff;
		opacity: 1;
		pointer-events: none;
	}
}

.icon-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
}
.reset-icon-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15px;
	height: 15px;
}

@keyframes blurAndBrightenInAndOut {
	50% {
		filter: blur(100px) brightness(0.5);
		-o-filter: blur(100px) brightness(0.5);
		-ms-filter: blur(100px) brightness(0.5);
		-moz-filter: blur(100px) brightness(0.5);
		-webkit-filter: blur(100px) brightness(0.5);
		backdrop-filter: blur(100px);
		-o-backdrop-filter: blur(100px);
		-ms-backdrop-filter: blur(100px);
		-moz-backdrop-filter: blur(100px);
		-webkit-backdrop-filter: blur(100px);
	}
}

// This is a css class within A-frame library.
// Our users should not be seeing this functionality
.a-enter-vr-button {
	visibility: hidden;
}
