.zoomTransitionContainer {
	position: absolute;
	display: flex;
	width: inherit;
	height: inherit;
	z-index: 5;
	justify-content: center;
	align-items: center;
	filter: blur(10px);
	-o-filter: blur(10px);
	-ms-filter: blur(10px);
	-moz-filter: blur(10px);
	-webkit-filter: blur(10px);
	backdrop-filter: blur(10px);
	-o-backdrop-filter: blur(10px);
	-ms-backdrop-filter: blur(10px);
	-moz-backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	animation: blurInAndOut 5s infinite;
	-webkit-animation: blurInAndOut 5s infinite;
}

@keyframes blurInAndOut {
	50% {
		filter: blur(100px);
		-o-filter: blur(100px);
		-ms-filter: blur(100px);
		-moz-filter: blur(100px);
		-webkit-filter: blur(100px);
	}
}
@-webkit-keyframes block {
	50% {
		filter: blur(100px);
		-o-filter: blur(100px);
		-ms-filter: blur(100px);
		-moz-filter: blur(100px);
		-webkit-filter: blur(100px);
	}
}
