.sessionOptionsContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-block: 8px;
	width: 100%;
	gap: 8px;
	.roundedButton {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #1e1f22;
		filter: brightness(0.8);
		z-index: 4;
		cursor: pointer;
		&.red {
			background-color: #d92e2e;
		}
		&.gray {
			background: #aaaaaa;
		}
		&.disabled {
			background-color: #aaaaaa;
			cursor: default;
		}
		&.rectangle {
			.rectangleLocalVoice {
				width: 0px;
				overflow: hidden;
			}
			.localVoiceText {
				overflow: hidden;
				display: none;
			}
		}
		&.rectangle:hover {
			width: 225px;
			opacity: 1 !important;
			border-radius: 50px;
			display: flex;
			justify-content: space-between;
			padding: 0 20px 0 12px;
			&.navSpeedRectangle {
				width: 250px;
			}
			.rectangleLocalVoice {
				width: 150px;
				height: 50px;
				opacity: 1 !important;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: default;
			}
			.localVoiceText {
				font-family: Roboto;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: #56ae4d;
				display: block;

				&.warning {
					color: #ffc400;
				}
			}
		}
	}
	.roundedButton:hover {
		filter: brightness(1);
	}

	.iconWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 26px;
		height: 26px;
	}
	.remoteVoiceWrapper {
		position: absolute;
		left: 120px;
	}
	.badge {
		position: absolute;
		top: 0;
		width: 20px;
		height: 20px;
		transform: translate(60%, 0);
	}
}

.speedControllerContainer {
	display: flex;
	align-items: center;
	color: #56ae4d;
	font-size: 12px;
	margin-right: 10px;
}

.indicatorContainer {
	.indicatorsWrapper {
		display: none;
		width: 150px;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		align-items: center;
		padding-left: 13px;
		.greyIndicator {
			width: 4px;
			height: 3px;
			border-radius: 2px;
			background-color: #8e8c89;
		}
		.yellowIndicator {
			background-color: #df760d !important;
		}
		.redIndicator {
			background-color: red !important;
		}
		.greenIndicator {
			background-color: #56ae4d !important;
		}
	}
}

.circleRipple {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.circleRippleEffect {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.circleRippleEffect:before,
.circleRippleEffect:after {
	position: absolute;
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	border: 2px solid white;
	border-radius: 50%;
}
.circleRippleEffect:before {
	animation: ripple 2s linear infinite;
}

.circleRippleEffect:after {
	animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
}
